<template>
  <div class="nft">
    <div class="wrapper">
      <div class="title font-md">
        <span>NFT 인증</span>
      </div>
      <div class="desc font-sm">
        <span v-if="$lib.isMobile()">아래 링크를 눌러 내용을 확인하신 후 인증 완료 버튼을 눌러주세요.</span>
        <span v-else>QR 코드를 스캔하신 후에 인증 완료 버튼을 눌러주세요.</span>
      </div>
      <div class="content">
        <div class="wrapper">
          <template v-if="$lib.isMobile()">
            <a :href="state.klipUrl" target="_blank" rel="noopener noreferrer" class="color-anchor font-sm">{{ state.klipUrl }}</a>
          </template>
          <template v-else>
            <img :src="state.qrImgUrl" alt="QR 코드" @load="onload()" v-show="state.loaded"/>
            <img class="placeholder" src="/assets/img/modal.nft.qr.svg" alt="QR 코드" v-show="!state.loaded"/>
          </template>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col">
          <button class="btn btn-point btn-block font-sm" @click="submit()">인증 완료</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "modalNft";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      const args = {
        bapp: {name: "오마이컴퍼니 NFT 리워드",},
        type: "auth"
      };

      http.post("https://a2a-api.klipwallet.com/v2/a2a/prepare", JSON.stringify(args)).then(({data}) => {
        state.requestKey = data.request_key;

        if (!state.requestKey) {
          return store.commit("setSwingMessage", definitions.messages.errorCommon);
        }

        const urls = [];

        if (lib.isAppleMobile()) {
          urls.push("kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
        } else if (lib.isAndroidMobile()) {
          urls.push("intent://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=" + data.request_key + "#Intent;scheme=kakaotalk;package=com.kakao.talk;end");
        } else {
          urls.push("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
        }

        state.klipUrl = urls.join("");

        if (!lib.isMobile()) {
          state.qrImgUrl = "https://chart.googleapis.com/chart?cht=qr&chs=170x170&chl=" + window.encodeURIComponent(state.klipUrl);
        }
      });

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      klipUrl: "",
      qrImgUrl: "",
      loaded: false,
      requestKey: ""
    });

    const onload = () => {
      state.loaded = true;
    };

    const submit = () => {
      fetch(`https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${state.requestKey}`).then((res) => res.json()).then((data) => {
        const klaytnAddress = data?.result?.klaytn_address;

        if (!klaytnAddress) {
          return store.commit("setSwingMessage", `NFT 지갑 주소를 가져올 수 없습니다. ${lib.isMobile() ? "KLIP 열기 링크를 클릭해주세요." : "스마트폰으로 QR 코드를 스캔해주세요."}.`);
        }

        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {
              modal,
              params: klaytnAddress
            });

            store.commit("setSwingMessage", "NFT 지갑 주소를 가져왔습니다.");
          }
        });
      });
    };

    return {component, state, onload, submit};
  },
});
</script>

<style lang="scss" scoped>
.nft {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px20;
      color: #666;
    }

    .content {
      text-align: center;

      > .wrapper {
        display: inline-block;

        img {
          width: $px170;
          height: $px170;
        }
      }
    }
  }

  .actions {
    .row {
      > div {
        button {
          padding: $px15;
          height: $px56;
          border-radius: 0;
        }

        &.left {
          padding-right: 0;
        }

        &.right {
          padding-left: 0;
        }
      }
    }
  }
}
</style>